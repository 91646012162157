import React from 'react';
import "./Home.css";
import { Link } from 'react-router-dom';
function HomePage() {
  return (
    <div className="home-page">
      <header className="hero">
        <h1>Welcome to the Tottenham Hotspur Fan Club!</h1>
        <p>Join us as we celebrate the legacy, passion, and spirit of Spurs!</p>
      </header>
      <section className="team-image-section">
        <Link to="/players">
          <img
            src="https://resources.premierleague.pulselive.com/photo-resources/2024/08/06/8d315998-8490-4cf1-8226-9b6c125e2aa0/Tottenham.jpg?width=1400&height=800"
            alt="Tottenham Team 2024"
            className="team-image"
          />
        </Link>
      </section>
    </div>
  );
}

export default HomePage;
