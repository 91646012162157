import { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import PlayerList from './PlayerList';
import PlayerDetail from './PlayerDetail';
import Header from './Header';
import HomePage from './Home';



function App() {
  const BACKEND_URL = "https://me23sb.leedsnewmedia.net/backend_tottenham.php"
  const [playersData, setPlayersData] = useState([]);
  const [error, setError] = useState(null);
  const fetchPlayers = async () => {
    try {
      const res = await fetch(`${BACKEND_URL}`);
      const data = await res.json()
      const players = data.data
      return players;
    } catch (error) {
      console.error('Error fetching players', error);
      setError(error);
      return [];
    }
  }

  useEffect(() => {
    const fetchPlayersData = async () => {
      const players = await fetchPlayers();
      setPlayersData(players);
    };

    fetchPlayersData();
  }, []);

  if (error) {
    return <p>Error: {error.message}</p>;
  }

  if (!playersData.length && !error) {
    return <p>Loading...</p>;
  }


  return (
    <Router>
      <div style={{ padding: '20px', fontFamily: 'Arial, sans-serif' }}>
        <Header />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/players" element={<PlayerList players={playersData} />} />
          <Route path="/players/:id" element={<PlayerDetail players={playersData} />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
