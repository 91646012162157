import React from 'react';
import { Link } from 'react-router-dom';
import './PlayerList.css';
import "./unkown_player.jpg";

function PlayerList({ players }) {
  return (
    <div className="player-list">
      <h2>Player List</h2>
      <ul>
        {players.map((player, index) => (
          <li key={index}>
            <Link to={`${player.id}`}>
              <img src={player?.image || require("./unkown_player.jpg")} alt={player.name} />
              {player.name} ({player.position})
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
}


export default PlayerList;
