import React from 'react';
import "./Header.css";
function Header() {
  return (
    <header className="header">
      <div className="header-content">
        <img
          src="https://upload.wikimedia.org/wikipedia/en/b/b4/Tottenham_Hotspur.svg"
          alt="Tottenham Hotspur Logo"
          className="club-logo"
        />
        <h1>Tottenham Hotspur Fan Club</h1>
        <nav className="nav-menu">
          <a href="/">Home</a>
          <a href="/players">Players</a>
        </nav>
      </div>
    </header>
  );
}

export default Header;
