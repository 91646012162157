import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import "./PlayerDetail.css";

function PlayerDetail({ players }) {
  const { id } = useParams();
  const player = players.find(p => parseInt(p.id) === parseInt(id));
  const navigate = useNavigate();

  if (!player) {
    return <p>Player not found.</p>;
  }

  const handleSearchGoogle = () => {
    const searchQuery = `${player.name} Tottenham`;
    const searchUrl = `https://www.google.com/search?q=${encodeURIComponent(searchQuery)}`;
    window.open(searchUrl, "_blank");
  };

  return (
    <div className="player-detail">
      <button onClick={() => navigate(-1)}>Back</button>
      <h2>{player.name}</h2>
      <img src={player?.image || require('./unkown_player.jpg')} alt={player.name} />
      <p><strong>Position:</strong> {player.position}</p>
      <p><strong>About him:</strong> {player.info}</p>
      <div className="buttons-container">
        <button onClick={handleSearchGoogle}>Search Google</button>
      </div>
    </div>
  );
}

export default PlayerDetail;
